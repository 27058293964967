<!--公共模块--时间设置趋势列表-->
<template>
  <div
    id="timeSetupTrendList"    
    element-loading-text="$t('dataCompareTrendList.a1')"
    ref="timeSetupTrendList"
  >
    <div class="main" >
      <div class="main-all-vessel" v-loading="loading">
        <a-table
          :columns="columns"
          :dataSource="dataList"          
          :pagination="pagination"
        >
        </a-table>
      </div>      
    </div>
    <div class="footer">
      <a-button type="primary" style="width: 120px"  @click="output" >{{$t('dataCompareTrendList.a2')}}</a-button>
      <a-button type="primary" style="width: 120px"  @click="reload" >{{$t('dataCompareTrendList.a3')}}</a-button>
    </div>  
  </div>      
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import headerTitle from "../../../components/Title/headerTitle";
import {getTimeTrendData} from "../../../api/device";
import { downloadCSVUrl } from "../../../utils/utils";
import moment from 'moment';

export default {
  props:{
    signalPoints:Array ,
    axisformat:{},
  },
  data() {
    return {      
      loading: true,
      dataList: [],
      date:["","","","","",""],
      dateString:this.$t('dataCompareTrendList.a4'),
      pagination: {
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total+this.$t('dataCompareTrendList.a5')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {          
          title: 'TagPoint',          
          width: 80,
          children: [
            {
              title: this.$t('dataCompareTrendList.a6'),
              children: [
                {
                  title: this.$t('dataCompareTrendList.a7'),
                  children: [
                    {
                      title: this.$t('dataCompareTrendList.a8'),
                      children: [
                        {
                          title: this.$t('dataCompareTrendList.a9'),
                          children: [
                          {
                            title: this.$t('dataCompareTrendList.a10'),
                            dataIndex: "time",
                            width: 80,
                          },            
                        ],
                          width: 80,
                        },            
                      ],
                      width: 80,
                    },            
                  ],
                  width: 80,
                },            
              ],
              
            },            
          ],                  
        },                    
      ],
    };
  },
  mounted() {    
    this.showsignalPoints();
    this.getData(); 
  },
  watch: {
    pluginsDataChange (newval, oldval) {
        this.getData();
    }
  },
  computed: {
    pluginsDataChange () {
        return JSON.stringify(this.axisformat)
    }
  },
  methods: {   
    showsignalPoints(){
      console.log("compareTrendList signalPoints:",this.signalPoints);
      console.log("compareTrendList axisformat:",this.axisformat);
      if(this.signalPoints!=null&&this.signalPoints.length>0){        
        for(let i=0;i<this.signalPoints.length;i++){  
          let column =
          {            
            title: this.signalPoints[i].tagPoint,            
            width: 100,
            children: [
              {
                title: this.signalPoints[i].signalTypeDesc,
                width: 100,
                children: [
                  {
                    title: this.signalPoints[i].dvName,
                    width: 100,
                    children: [
                      {
                        title: this.signalPoints[i].signalName,
                        width: 100,
                        children: [
                          {
                            title: this.$t('dataCompareTrendList.a11'),
                            width: 100,
                            children: [
                              {
                                title: this.axisformat.dateString,
                                dataIndex: "value"+(i+1),
                                width: 100,
                              },
                            ],                            
                          },
                        ],                        
                      }, 
                    ],                   
                  }, 
                ],                
              }, 
           ],            
          }
          this.columns.push(column);
        } 
      }
    },
    output(){
      let title =[{title:"A",field:"A"},{title:"B",field:"B"},{title:"C",field:"C"}];
      let data = [{A:1,B:2,C:3},{A:1,B:2,C:3},{A:1,B:2,C:3},{A:1,B:2,C:3}];
      let fileName = this.$t('timeSetupTrend.a1')+'_'+moment().format('YYYY-MM-DD HH:mm:ss');
      this.JSONToCSVConvertor(fileName);
    },

    /**
   * 导出CSV
   * @param {Object} fileName  文件名称
   */
    JSONToCSVConvertor(fileName) {
      var CSV = '';
      var row = "";
      row = this.$t('dataCompareTrendList.a6');      
      for (var i = 0; i < this.signalPoints.length; i++) {        
        row += ','+this.signalPoints[i].tagPoint;
      }      
      CSV += row + '\r\n';
      row = this.$t('dataCompareTrendList.a7');      
      for (var i = 0; i < this.signalPoints.length; i++) {        
        row += ','+this.signalPoints[i].signalTypeDesc;
      }
      CSV += row + '\r\n';
      row = this.$t('dataCompareTrendList.a8');      
      for (var i = 0; i < this.signalPoints.length; i++) {        
        row += ','+this.signalPoints[i].dvName;
      }
      CSV += row + '\r\n';
      row = this.$t('dataCompareTrendList.a9');      
      for (var i = 0; i < this.signalPoints.length; i++) {        
        row += ','+this.signalPoints[i].signalName;
      }
      CSV += row + '\r\n';
      row = this.$t('dataCompareTrendList.a10');      
      for (var i = 0; i < this.signalPoints.length; i++) {        
        row += ','+this.axisformat.dateString;
      }
      CSV += row + '\r\n';      
      for (var i = 0; i < this.dataList.length; i++) {        
        let trendData=this.dataList[i];        
        row = trendData.time;
        for (var j = 0; j < this.signalPoints.length; j++) {  
          if(j==0)          
            row += ','+trendData.value1;
          if(j==1)
            row += ','+trendData.value2;
          if(j==2)
            row += ','+trendData.value3;
          if(j==3)
            row += ','+trendData.value4;
          if(j==4)
            row += ','+trendData.value5;
          if(j==5)
            row += ','+trendData.value6;
        }
        CSV += row + '\r\n'; 
      }    
      
      if (CSV == '') {
        alert("Invalid data");
        return;
      }      
      downloadCSVUrl(CSV,fileName);
    },

   /**
   * 导出CSV
   * @param {Object} title  标题列key-val
   * @param {Object} data   值列key-val
   * @param {Object} fileName  文件名称
   */
    JSONToExcelConvertor(title, data, fileName) {
      var CSV = '';
      var row = "";
      
      for (var i = 0; i < title.length; i++) {
        if(title[i].title){
        row += title[i].title + ',';
        }
      }
      row = row.slice(0, -1);
      CSV += row + '\r\n';
      
      for (var i = 0; i < data.length; i++) {
        var row = "";
        for (var j = 0; j < title.length; j++) {
        if(title[j].title){
          row += '"' + (data[i][title[j].field] ? data[i][title[j].field] : "") + '"\t,';
        }
        }
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
      }
      
      if (CSV == '') {
        alert("Invalid data");
        return;
      }      
      downloadCSVUrl(CSV,fileName);
    },
    reload(){//重新加载数据
      this.getData();  
    },         
    getData(){
      let tagPoints="";
      let tagPoint = "";
      this.loading=true;
      if(this.signalPoints!=null && this.signalPoints.length>0){
        for(let i=0;i<this.signalPoints.length;i++){
          tagPoint = this.signalPoints[i].signalNo+"@@"+this.signalPoints[i].signalKind+"@@"+this.signalPoints[i].trendType;
          if(tagPoints=="")
            tagPoints = tagPoint;
          else
            tagPoints = tagPoints+","+tagPoint;
        }
      }
      
      let par = {
        tagPoints: tagPoints,
        date: this.axisformat.dateString,
        time: this.axisformat.timeString,
        div: this.axisformat.timeView,
        pvtype: 1
      };       
      console.log("getTrendData par",par); 
      getTimeTrendData(par).then((res) => {
        let { data } = res;
        this.dataList=data;      
        if(this.dataList!=null&&this.dataList.length>0){
          for(let i=0;i<this.dataList.length;i++){
            this.dataList[i].key=i;
          }
        }  
        console.log("getTrendData：",this.dataList);              
      })
      .catch((err) => {
        console.log(err);
      });
      this.loading=false;
    }   
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#timeSetupTrendList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 10px 20px 0px 20px;
}
.inquire {
  height: 50px;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 10px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>